body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'casper';
  src: url(./static/fonts/Casper/Casper.ttf) format("truetype")
}

@font-face {
  font-family: 'inter';
  src: url(./static/fonts/Inter/Inter-VariableFont_slnt,wght.ttf) format("truetype")
}

html { scroll-padding-top: 90px; }


.d28 {
  height: 50px;
  width: 160px;
  margin: 6px;
  position: relative;
}
.d28:before,
.d28:after {
  content: "";
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  background: #F45919;
}
.d28:before {
  left: 0;
  transform: skew(0deg, 10deg);
}
.d28:after {
  right: 0;
  transform: skew(0deg, -10deg);
}